<template>
  <section>
    <SideBarDetailRecord />
    <PhotosViwer v-if="showPhotosViwer" />
  </section>
</template>

<script>
export default {
  components: {
    SideBarDetailRecord: () => import("../components/mainRecords/SideBarDetailRecord.vue"),
    PhotosViwer: () => import("../components/mainRecords/PhotosViwer.vue"),
  },
  computed: {
    showPhotosViwer() {
      return this.$store.state.records.imagesToShow.images.length;
    },
  },
};
</script>
