import { io } from "socket.io-client";
import { parseJwt } from "@/common/session";

class SocketService {
  constructor() {
    this.socket = null;
  }

  connect() {
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    const user = parseJwt(token, false);
    const { role = "", _id = "", isSpecialFunctions = false, area = "", city = "" } = user || {};
    let query = "";

    if (_id) query += `&_id=${_id}`;
    if (role) query += `&role=${role}`;
    if (area) query += `&area=${area}`;
    if (city) query += `&city=${city}`;
    if (isSpecialFunctions) query += `&isSpecialFunctions=${isSpecialFunctions}`;

    if (!this.socket) {
      this.socket = io(process.env.VUE_APP_SOCKET, {
        path: "/api/v1/socket.io",
        auth: { [process.env.VUE_APP_TOKEN_NAME]: token },
        query,
        transports: ["websocket"],
      });

      this.socket.on("connect", () => {
        console.log("Conexión establecida con el servidor");
      });

      this.socket.on("error", (error) => {
        console.error("Error al conectar al servidor:", error);
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  on(eventName, callback) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    }
  }

  emit(eventName, payload) {
    if (this.socket) {
      this.socket.emit(eventName, payload);
    }
  }

  off(eventName, callback) {
    if (this.socket) {
      this.socket.off(eventName, callback);
    }
  }
}

export default new SocketService();
