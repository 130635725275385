<template>
  <section class="adminMain">
    <Header class="adminMain__header" />
    <div :class="`adminMain__contentViews adminMain__contentViews--${isUpZidx ? 'menu' : ''}`">
      <Menu :class="[`adminMain__menu adminMain__menu--${menuState}`, isMobile && 'adminMain__absolute']" />
      <div class="adminMain__menuClose" v-if="isUpZidx" @click="$store.commit('changeMenuState', 'hidden')"></div>
      <div :class="`adminMain__views adminMain__views--${classToAply}`" @scroll="scrolling">
        <router-view />
      </div>
    </div>
    <ViewsController />
  </section>
</template>

<script>
import Header from "../components/mainRecords/Header.vue";
import scroller from "../assets/js/scroller";
import ViewsController from "./ViewsController.vue";
import SocketService from "../socket";
export default {
  components: { Header, ViewsController, Menu: () => import("../components/mainRecords/Menu.vue") },
  data() {
    return {
      class: {
        short: "close",
        long: "open",
      },
    };
  },
  computed: {
    isMobile() {
      return this.$screen.width < 1024;
    },
    menuState() {
      return this.$store.state.menuState;
    },
    isUpZidx() {
      return this.isMobile && this.menuState === "long";
    },
    classToAply() {
      return !this.isMobile ? this.class[this.menuState] || "" : "";
    },
    classToAplyMobile() {
      return this.isMobile ? this.class[this.menuState] || "" : "";
    },
  },
  methods: {
    scrolling: function ({ srcElement }) {
      scroller.isBottomScroll({ srcElement, offsetGap: 1 }, async () => {
        let elems = document.querySelectorAll(".scroll-bottom");
        [].forEach.call(elems, function (el) {
          if (el) el.click();
        });
      });
    },
  },
  async beforeMount() {
    SocketService.connect();
    await this.$store.dispatch("getAllCities");
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  color: $primary-color;
  font-weight: bold;
  font-family: $sec_font;
}
.adminMain {
  height: 100vh;
  width: 100vw;
  &__header {
    position: fixed;
    top: 0;
    z-index: 3;
  }
  &__contentViews {
    position: relative;
    @include Flex(row, flex-start);
    height: 100%;
    width: 100%;
    &--menu {
      z-index: 4;
    }
  }
  &__absolute {
    position: fixed;
    top: 0;
  }
  &__menu {
    width: 90px;
    transition: 0.3s ease-in-out;
    z-index: 2;
    &--long {
      width: 250px;
    }
    &--short {
      width: 90px;
    }
    &--hidden {
      width: 0px;
    }
    &--open {
      width: 250px;
    }
  }
  &__menuClose {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__views {
    @include Flex(column, flex-start, center);
    position: relative;
    z-index: 0;
    height: 100%;
    width: 100%;
    padding-top: 49px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &--close {
      width: calc(100% - 90px);
    }
    &--open {
      width: calc(100% - 250px);
    }
  }
}
</style>
