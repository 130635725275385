<template>
  <section class="headerRecords">
    <div class="headerRecords__section">
      <a class="headerRecords__mobileMenuButton" @click="changeMenu('long')" v-if="isMobile">
        <iconic name="bars" />
      </a>
      <router-link class="headerRecords__appTitle" :to="{ name: '' }" v-else>MySpace 1A</router-link>
    </div>
    <div class="headerRecords__section">
      <div class="headerRecords__appsBtn" @click="$refs.AppsMenu.toggleMenu()">
        <iconic name="apps" />
      </div>
      <div class="name" v-if="!isMobile">{{ user.user }}</div>
      <Avatar class="headerRecords__avatar" :user="user" @click.native="$refs.myUserMenu.toggleMenu()" />
    </div>
    <AppsMenu ref="AppsMenu" noprint></AppsMenu>
    <UserMenu ref="myUserMenu" :user="user" noprint></UserMenu>
  </section>
</template>

<script>
export default {
  components: {
    Avatar: () => import("@/common/Avatar.vue"),
    AppsMenu: () => import("@/common/AppsMenu.vue"),
    UserMenu: () => import("@/common/userMenu.vue"),
  },
  computed: {
    user() {
      return this.$store.state.userProfile;
    },
    isMobile() {
      return this.$screen.width < 1024;
    },
  },
  methods: {
    changeMenu(state) {
      this.$store.commit("changeMenuState", state);
    },
  },
};
</script>

<style lang="scss">
.headerRecords {
  @include Flex(row, space-between);
  gap: 10px;
  height: $header_height;
  width: 100%;
  padding: 15px;
  background-color: $white;
  box-shadow: 0 0 7.5px $alto;
  &__section {
    @include Flex(row);
    gap: 10px;
  }
  &__mobileMenuButton {
    @include Flex(inherit, center, center);
    height: $header_height;
    padding: $mpadding/2;
    color: $primary_color;
    cursor: pointer;
  }
  &__appTitle {
    font-family: $third_font;
    font-size: 17px;
    font-weight: bold;
    color: $primary-color;
    &:hover {
      text-decoration: none;
    }
  }
  &__appsBtn {
    padding: $mpadding;
    font-size: 120%;
    color: $primary_color;
    cursor: pointer;
  }
  &__avatar {
    width: 38px;
    height: 38px;
    font-size: 18px;
    border: 1px solid $primary_color;
    cursor: pointer;
  }
}
</style>
